@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@media (max-width: 540px) {
    html, body {
        height: 100%;
        overflow-y: auto;
    }
}


#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}

.App {
    color: white;
}

[ data-darkmode=false ] .App {
    color: #222;
}

.resume-button {
    color: white;
    box-shadow: inset 0 0 0.5px 1px hsla(0, 0%,  
    100%, 0.075),
    0 0 0 1px hsla(0, 0%, 0%, 0.05),
    0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
    0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
    0 3.5px 6px hsla(0, 0%, 0%, 0.09);
}

[ data-darkmode=false ] .resume-button {
    color: #222;
}