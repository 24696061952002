.cp-transition__container {
  height: 100%;
  min-height: 500px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1545px;
  align-self: center;
}

/* @media (max-width: 768px) {
.cp-transition__container {
  min-height: 250px;
}
} */

.square {
position: relative;
height: 100%;
width: 33%;
max-width: 415px;
/* max-height: 690px; */
margin: 26px;
border-radius: 15px;
box-shadow: var(--shadow-md);
transition: all 0.2s;
cursor: pointer;
background-color: #1A1A2E;
box-shadow: inset 0 0 0.5px 1px hsla(0, 0%,  
100%, 0.075),
0 0 0 1px hsla(0, 0%, 0%, 0.05),
0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
0 3.5px 6px hsla(0, 0%, 0%, 0.09);
}

[data-darkmode=false] .square {
background-color: #ffe7de;
/* box-shadow: 0 2px 16px rgba(0,0,0,0.18); */
}

.square:hover {
transform: translateY(-4px) !important;
}

.cp-transition__squares-wrapper {
height: 100%;
display: flex;
/* justify-content: center; */
align-items: center;
background: transparent;
width: 92%;
overflow-x: auto;
}

.cp-transition__squares-wrapper::-webkit-scrollbar {
display: none;
}

.card {
color: white;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
width: 90%;
max-width: 1400px;
display: flex;
background: linear-gradient(to right, #1A1A2E 0%, #16213E 50%, #0F3460 100%);
box-shadow: 0 2px 12px rgba(255,255,255,.12);
}


[data-darkmode=false] .card {
background: linear-gradient(to right, #F5EBE0 0%, #EEE3CB 50%, #FFFBEB 100%);
color: #222;
box-shadow: 0 2px 16px rgba(0,0,0,0.18);
}


.card__header h2 {
font-size: 3.2rem;
font-weight: 700;
}

.card__header button:focus {
outline: none;
}

.project-card-img {
aspect-ratio: 1.399;
width: 50%;
object-fit: cover;
/* height: calc(100% + 2px); */
}

/* #project {
margin-top: -1172px;
} */

.square-img {
  aspect-ratio: 1.399;
  object-fit: cover;
}

@media (max-width: 540px) {
.square {
  width: 82%;
  margin-top: 16px;
}
.project-card-img {
  width: 100%;
}
.card__header h2{
  font-size: 16px;
}
}

.scroll-btn {
height: 20px;
width: 20px;
fill: #0047BB;
cursor: pointer;
position: absolute;
top: 48%;
z-index: 2000;
}

.prev-btn {
left: calc(2.4% - 16px);
}

.next-btn {
right: calc(2.4% - 16px);
}

[data-darkmode = false] .scroll-btn {
  fill: #FFD0B7;
}