.experience-timeline {
    background-color: white;
    margin-top: -144px;
    height: 510px;
}

[data-darkmode=false] .experience-timeline {
    background-color: #222;
}
.timeline-dots {
    width: 9px;
    height: 9px;
    left: calc(50% - 4.5px);
}

[data-darkmode=false] .timeline-dots {
    background-color: #222;
}

