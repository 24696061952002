.email-form-wrapper {
    /* min-width: 630px; */
    max-width: 800px;
    width: 60%;
    color: white;
    box-shadow: inset 0 0 0.5px 1px hsla(0, 0%,  
    100%, 0.075),
    0 0 0 1px hsla(0, 0%, 0%, 0.05),
    0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
    0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
    0 3.5px 6px hsla(0, 0%, 0%, 0.09);
}

[data-darkmode = false] .email-form-wrapper {
    color: #222;
}

.email-form-button {
    background-color: #1A1A2E;
}

[data-darkmode = false] .email-form-button {
    background-color: #FFE7DE;
}

.form-input {
    color: #222;
}

.form-textarea {
    color: #222;
    /* padding: 24px 20px 24px 20px; */
}

@media (max-width: 540px) {
    .form-textarea {
        padding: 12px;
    }
}